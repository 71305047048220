@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}

hr {
    color: lightgray;
    margin-left: 2em;
    margin-right: 2em;
}
