.submit-update-form-container {
  margin: 20px auto 50px;
  width: 80vw;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #999;
}

.submit-update-form-container * {
  margin-left: 0;
}

.submit-update-form-container label, 
.submit-update-form-container p {
  display: block;
  margin-bottom:  5px;
}

input, select {
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 15px;
  border: 0.6px solid gray;
  background-color: transparent;
}

input[type="text"]:focus {
  border: 1px solid rgb(16, 150, 24);
  outline: none;
}

input[type="submit"], 
input[type="date"],
.date-picker,
select {
  display: block; 
  padding: 10px 20px;
  letter-spacing: 1.25px;
}

input[type="submit"]:hover {
  background: #999;
  cursor: pointer;
}