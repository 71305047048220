div.content {
    text-align: left;
}
@media only screen and (min-width: 600px) {
    div.content {
        padding: 2em;
        margin-left: 2em;
        margin-right: 2em;
    }
}
