div.section {
    padding: 2em;
    padding-top: 0;
    padding-bottom: 4em;
    text-align: center;
    align-content: center;
}
div.component {
    margin-left: auto;
    margin-right: auto;
}
.section-title {
    padding: 1em;
}
