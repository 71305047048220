@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}

hr {
    color: lightgray;
    margin-left: 2em;
    margin-right: 2em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-lg {
    background-color: rgba(255, 255, 255, 0.4);
}
.navbar-sm {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 20;
    top: 0;
}
.icon-lg {
    transform: scale(1.5);
}

@media only screen and (max-width: 600px) {
    .navbar-item {
        width: 100%;
    }
}
.navbar-item {
    display: inline-block;
    list-style-type: none;
}

.nav-title {
    float: left;
    padding-left: 1em;
}
@media only screen and (max-width: 600px) {
    .nav-title {
        float: none;
        padding-left: 0;
    }
    .ul-navbar-sm {
        padding-left: 0;
    }
    .navbar-sm {
        padding-top: 1em;
    }
}

.map-container {
  margin: 0 auto 100px;
  width: 90vw;
}

.map-container path {
  stroke-width: 0.5; 
  stroke:#000;
}

.map-legend {
  text-align: left;
  position: absolute;
  right: 5vw;
  bottom: 20%;
}

@media only screen and (max-width: 900px) {
  .map-container {
    margin-bottom: 20px;
  }

  .map-legend {
    position: static;
  }

  .map-legend ul {
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.map-legend div {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.map-legend li {
  list-style: none;
}
div.content {
    text-align: left;
}
@media only screen and (min-width: 600px) {
    div.content {
        padding: 2em;
        margin-left: 2em;
        margin-right: 2em;
    }
}

div.section {
    padding: 2em;
    padding-top: 0;
    padding-bottom: 4em;
    text-align: center;
    align-content: center;
}
div.component {
    margin-left: auto;
    margin-right: auto;
}
.section-title {
    padding: 1em;
}

.mail-icon,
.mail-link {
    vertical-align: middle;
    display: inline-block;
}
.mail-icon {
    padding-right: 5px;
}
.section-title-contact {
    padding-top: 0.5em;
}

.submit-update-form-container {
  margin: 20px auto 50px;
  width: 80vw;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #999;
}

.submit-update-form-container * {
  margin-left: 0;
}

.submit-update-form-container label, 
.submit-update-form-container p {
  display: block;
  margin-bottom:  5px;
}

input, select {
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 15px;
  border: 0.6px solid gray;
  background-color: transparent;
}

input[type="text"]:focus {
  border: 1px solid rgb(16, 150, 24);
  outline: none;
}

input[type="submit"], 
input[type="date"],
.date-picker,
select {
  display: block; 
  padding: 10px 20px;
  letter-spacing: 1.25px;
}

input[type="submit"]:hover {
  background: #999;
  cursor: pointer;
}
