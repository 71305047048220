.navbar-lg {
    background-color: rgba(255, 255, 255, 0.4);
}
.navbar-sm {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
}
.sticky {
    position: sticky;
    z-index: 20;
    top: 0;
}
.icon-lg {
    transform: scale(1.5);
}
