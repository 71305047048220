.map-container {
  margin: 0 auto 100px;
  width: 90vw;
}

.map-container path {
  stroke-width: 0.5; 
  stroke:#000;
}

.map-legend {
  text-align: left;
  position: absolute;
  right: 5vw;
  bottom: 20%;
}

@media only screen and (max-width: 900px) {
  .map-container {
    margin-bottom: 20px;
  }

  .map-legend {
    position: static;
  }

  .map-legend ul {
    margin: 0 auto;
    width: fit-content;
  }
}

.map-legend div {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.map-legend li {
  list-style: none;
}